<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :multi-line="true"
      :timeout="timeout"
    >
      {{ errorText }}
    </v-snackbar>
    <v-dialog v-model="addPurchaseDialog" width="700">
      <v-card>
        <v-card-title>Nuova procedura di acquisto</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newDescription"
            label="Descrizione"
          ></v-text-field>
          <!-- <v-textarea v-model="newNote" label="Note"></v-textarea> -->
          <v-autocomplete
            v-model="thisUser"
            :loading="usersLoading"
            :items="allusers"
            :search-input.sync="searchuser"
            item-text="text"
            item-value="MATRICOLA"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            return-object
            label="Aggiungi richiedente"
          ></v-autocomplete>
          <br />
          <v-row v-for="(user, index) of thisUsers" :key="user.MATRICOLA">
            <v-spacer></v-spacer>
            <v-col cols="8">
              - {{ user.COGNOME }} {{ user.NOME }} ({{ user.MATRICOLA }})
            </v-col>
            <v-col>
              <v-btn icon @click="thisUsers.splice(index, 1)">
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
        <v-card-text>
          {{ groupNotifyLabel }}:<br />
          <v-row v-for="user of currentGroupUsers" :key="user.MATRICOLA" dense>
            <v-spacer></v-spacer>
            <v-col cols="10">
              <input
                type="checkbox"
                dense
                v-model="selectedGroupUsers"
                :value="{
                  matricola: user.MATRICOLA,
                  fullname: user.COGNOME + ' ' + user.NOME,
                }"
                :label="user.COGNOME + ' ' + user.NOME"
              />
              {{ user.COGNOME + " " + user.NOME }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <input
                type="checkbox"
                dense
                v-if="defaultfsm"
                v-model="notifyUsers"
                :label="userNotifyLabel"
              />
              {{ userNotifyLabel }}
            </v-col>
          </v-row>
          <br />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="newDescription.length === 0 || thisUsers.length === 0"
            @click="addPurchase()"
            >OK</v-btn
          >
          <v-btn @click="addPurchaseDialog = false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-card elevation="12" min-height="600">
          <v-card-title>Acquisti</v-card-title>
          <v-card-text>
            <v-row v-show="loading" justify="center">
              <v-col cols="2">
                <br />
                <br />
                <br />
                <v-progress-circular
                  color="primary"
                  indeterminate
                  :size="70"
                  :width="7"
                />
                <br />
                <br />
                <br />
              </v-col>
            </v-row>
            <v-row v-show="!loading" justify="center">
              <v-col
                v-if="userRole === 'admin' || userRole === 'operator'"
                cols="6"
              >
                <v-btn @click="addPurchaseDialog = true">Nuovo acquisto</v-btn>
              </v-col>
            </v-row>
            <v-row v-show="!loading" justify="center">
              <v-col cols="12">
                <br />
                <v-checkbox
                  v-model="showClosed"
                  @change="rebuildPurchasesArray()"
                  label="Mostra acquisti completati o annullati"
                ></v-checkbox>
                <br />
                <v-data-table
                  elevation="10"
                  :headers="headers"
                  :items="purchases"
                  item-key="ID"
                  :search="search"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    itemsPerPageText: 'Acquisti per pagina',
                    itemsPerPageAllText: 'Tutti gli acquisti',
                    itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
                    // options: {
                    //   itemsPerPage: 50,
                    //   page: 1,
                    // },
                    pageText: '',
                  }"
                  :options.sync="pagination"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Filtra..."
                      class="mx-4"
                      itemsPerPageText="'Acquisti per pagina'"
                    />
                  </template>
                  <template v-slot:no-data>
                    <v-sheet> Nessun acquisto </v-sheet>
                  </template>
                  <template v-slot:no-results>
                    <v-sheet> Nessun acquisto </v-sheet>
                  </template>
                  <template v-slot:item.ID="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.ID
                    }}</span>
                  </template>
                  <template v-slot:item.description="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.description
                    }}</span>
                  </template>
                  <template v-slot:item.startedText="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.startedText
                    }}</span>
                  </template>
                  <template v-slot:item.UserText="{ item }">
                    <span
                      :class="item.closed ? 'text--disabled' : ''"
                      v-html="item.UserText"
                    ></span>
                  </template>
                  <template v-slot:item.stateText="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.stateText
                    }}</span>
                  </template>
                  <template v-slot:item.modifiedText="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.modifiedText
                    }}</span>
                  </template>
                  <template v-slot:item.competenzaText="{ item }">
                    <div
                      :class="item.closed ? 'text--disabled pa-1' : 'pa-1'"
                      :style="
                        'color: #fff; background-color: ' +
                        item.fsm.states[
                          item.PurchaseHistories[
                            item.PurchaseHistories.length - 1
                          ].state
                        ].meta.color
                      "
                    >
                      {{
                        item.fsm.states[
                          item.PurchaseHistories[
                            item.PurchaseHistories.length - 1
                          ].state
                        ].meta.responsible
                      }}<span
                        v-if="
                          item.financialAssignee &&
                          item.fsm.states[
                            item.PurchaseHistories[
                              item.PurchaseHistories.length - 1
                            ].state
                          ].meta.financial
                        "
                        :class="item.closed ? 'text--disabled pa-1' : 'pa-1'"
                        :style="
                          'color: #fff; background-color: ' +
                          item.fsm.states[
                            item.PurchaseHistories[
                              item.PurchaseHistories.length - 1
                            ].state
                          ].meta.color
                        "
                      >
                      </span>
                    </div>
                  </template>
                  <template v-slot:item.financialOperator="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.financialOperator
                    }}</span>
                  </template>
                  <template v-slot:item.started="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          :to="'/purchase/' + item.ID"
                        >
                          <v-icon small> mdi-eye-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizza</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <br />
                <br />
                <br />
                <br />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
const moment = require("moment-timezone");
import { mapState } from "vuex";

//const axios = require('axios');

export default {
  name: "Purchases",
  data: () => {
    return {
      purchases: [],
      purchasesOpen: [],
      purchasesClosed: [],
      showClosed: false,
      search: "",
      loading: false,
      addPurchaseDialog: false,
      newDescription: "",
      newNote: "",
      usersLoading: false,
      thisUser: null,
      thisUsers: [],
      allusers: [],
      searchuser: null,
      snackbar: false,
      snackbarColor: "",
      errorText: "",
      error: false,
      timeout: 5000,
      defaultfsm: null,
      currentGroupUsers: [],
      currentGroupName: null,
      selectedGroupUsers: [],
      groupNotifyLabel: "",
      userNotifyLabel: "Notifica agli utenti/richiedenti",
      notifyUsers: true,
      allPurchases: false,
      pagination: { totalItems: 2000, rowsPerPage: 50, page: 1 },
    };
  },
  created: function () {
    this.loadPurchaseList(false);
    this.$http
      .get(process.env.VUE_APP_PATRIMONIO_API + "/purchases/defaultfsm", {
        headers: {
          authorization: "Bearer " + this.$store.state.authJwt,
        },
      })
      .then((response) => {
        this.defaultfsm = response.data.fsm;
        this.groupNotifyLabel =
          "Notifica " +
          this.defaultfsm.states[this.defaultfsm.initial].meta.responsibleGroup;

        this.selectedGroupUsers = [];
        this.$http
          .get(
            process.env.VUE_APP_PATRIMONIO_API +
              "/users/getgroupbyname/" +
              this.defaultfsm.states[this.defaultfsm.initial].meta.ownersGroup,
            {
              headers: {
                authorization: "Bearer " + this.$store.state.authJwt,
              },
            }
          )
          .then((response) => {
            this.currentGroupUsers = response.data.Users;
            for (let user of this.currentGroupUsers) {
              this.selectedGroupUsers.push({
                matricola: user.MATRICOLA,
                fullname: user.COGNOME + " " + user.NOME,
              });
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        console.log(err);
      });
  },
  watch: {
    searchuser(val) {
      val && val !== this.thisUser && this.queryUsers(val);
    },
    thisUser(val) {
      val && this.thisUsers.push(val);
      this.thisUser = "";
    },
  },
  computed: {
    ...mapState(["userInitials", "userRole"]),
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "ID",
        },
        {
          text: "Inizio",
          align: "start",
          sortable: true,
          value: "startedText",
        },
        {
          text: "Descrizione",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Utente/i",
          align: "start",
          sortable: true,
          value: "UserText",
        },
        {
          text: "Ultimo aggiornamento",
          align: "start",
          sortable: true,
          value: "modifiedText",
        },
        {
          text: "Stato",
          align: "start",
          sortable: true,
          value: "stateText",
          // value: "PurchaseHistories",
        },
        {
          text: "Competenza",
          align: "start",
          sortable: true,
          value: "competenzaText",
        },
        {
          text: "Operatore amministrativo",
          align: "start",
          sortable: true,
          value: "financialOperator",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "started",
        },
      ];
    },
  },
  methods: {
    purchaseUsers(purchase) {
      if (purchase.Users.length === 0) {
        return "";
      } else {
        let returnString = "(";
        let firstUser = true;
        for (let user of purchase.Users) {
          if (!firstUser) {
            returnString += ", ";
          }
          firstUser = false;
          returnString += user.COGNOME + " " + user.NOME;
        }
        returnString += ")";
        return returnString;
      }
    },
    async loadPurchaseList(allPurchases) {
      
      this.loading = true;
      try {
        let response = await this.$http.get(
          process.env.VUE_APP_PATRIMONIO_API + "/purchases",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        );
        this.purchasesOpen = [];
        this.purchasesClosed = [];
        for (let purchase of response.data.purchases) {
          let UserText = "";
          let first = true;
          for (let user of purchase.Users) {
            if (!first) {
              UserText += ",<br/> ";
            }
            first = false;
            UserText += user.COGNOME + " " + user.NOME;
          }
          purchase.startedText = this.formatDateOnly(purchase.started);
          purchase.modifiedText = this.formatDate(
            purchase.PurchaseHistories[purchase.PurchaseHistories.length - 1]
              .date
          );
          purchase.UserText = UserText;
          purchase.stateText =
            purchase.fsm.states[
              purchase.PurchaseHistories[
                purchase.PurchaseHistories.length - 1
              ].state
            ].meta.fullStateName;
          purchase.competenzaText =
            purchase.fsm.states[
              purchase.PurchaseHistories[
                purchase.PurchaseHistories.length - 1
              ].state
            ].meta.responsible;
          if (purchase.financialAssignee) {
            purchase.financialOperator =
              purchase.financialAssignee.COGNOME +
              " " +
              purchase.financialAssignee.NOME;
          }
          if (purchase.closed) {
            this.purchasesClosed.push(purchase);
          } else {
            this.purchasesOpen.push(purchase);
          }
        }
        
        this.loading = false;
        this.rebuildPurchasesArray();
        response = await this.$http.get(
          process.env.VUE_APP_PATRIMONIO_API + "/purchases?allPurchases=1",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        );
        this.purchasesOpen = [];
        this.purchasesClosed = [];
        for (let purchase of response.data.purchases) {
          let UserText = "";
          let first = true;
          for (let user of purchase.Users) {
            if (!first) {
              UserText += ",<br/> ";
            }
            first = false;
            UserText += user.COGNOME + " " + user.NOME;
          }
          purchase.startedText = this.formatDateOnly(purchase.started);
          purchase.modifiedText = this.formatDate(
            purchase.PurchaseHistories[purchase.PurchaseHistories.length - 1]
              .date
          );
          purchase.UserText = UserText;
          purchase.stateText =
            purchase.fsm.states[
              purchase.PurchaseHistories[
                purchase.PurchaseHistories.length - 1
              ].state
            ].meta.fullStateName;
          purchase.competenzaText =
            purchase.fsm.states[
              purchase.PurchaseHistories[
                purchase.PurchaseHistories.length - 1
              ].state
            ].meta.responsible;
          if (purchase.financialAssignee) {
            purchase.financialOperator =
              purchase.financialAssignee.COGNOME +
              " " +
              purchase.financialAssignee.NOME;
          }
          if (purchase.closed) {
            this.purchasesClosed.push(purchase);
          } else {
            this.purchasesOpen.push(purchase);
          }
        }
        
        this.rebuildPurchasesArray();
        this.loading = false;
        

      } catch (err) {
        if (err.response) {
          console.log(err.response);
          this.errorText = err.response.statusText;
          this.snackbarColor = "red";
          this.snackbar = true;
        } else if (err.request) {
          this.errorText = "Errore in caricamento acquisti";
          this.snackbarColor = "red";
          this.snackbar = true;
        } else {
          console.log(err);
        }
        this.loading = false;
        this.error = false;
      }
    },
    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, HH:mm");
    },
    formatDateOnly(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("D MMM YYYY");
    },
    async rebuildPurchasesArray() {
      if (!this.allPurchases && this.showClosed) {
        this.allPurchases = true;
        // await this.loadPurchaseList(true);
      }
      this.purchases = this.purchasesOpen.slice();
      if (this.showClosed) {
        for (let purchase of this.purchasesClosed) {
          this.purchases.push(purchase);
        }
      }
      this.pagination.itemsPerPage = 50;

    },
    addPurchase() {
      const component = this;
      component.loading = true;
      let formData = new FormData();
      let users = [];
      for (let user of this.thisUsers) {
        users.push(user.MATRICOLA);
      }
      // let data = {
      //   description: component.newDescription,
      //   note: component.newNote,
      //   users: users,
      // };
      if (component.newDescription) {
        formData.append("description", this.newDescription);
      }
      if (users.length > 0) {
        formData.append("users", JSON.stringify(users));
        // formData.append("description", users);
      }
      if (component.selectedGroupUsers.length > 0) {
        formData.append(
          "notifyGroupUsers",
          JSON.stringify(component.selectedGroupUsers)
        );
      }
      if (component.notifyUsers) {
        formData.append("notifyUsers", component.notifyUsers);
      }

      component.$http
        .post(process.env.VUE_APP_PATRIMONIO_API + "/purchases", formData, {
          headers: {
            authorization: "Bearer " + component.$store.state.authJwt,
          },
        })
        .then(function () {
          component.addPurchaseDialog = false;
          component.loadPurchaseList();
        })
        .catch(function (err) {
          if (err.response) {
            console.log(err.response);
            component.errorText = err.response.statusText;
            component.snackbarColor = "red";
            component.snackbar = true;
          } else if (err.request) {
            component.errorText = "Errore in caricamento acquisti";
            component.snackbarColor = "red";
            component.snackbar = true;
          } else {
            console.log(err);
          }
          this.error = true;
          this.loading = false;
        });
    },
    queryUsers(v) {
      this.usersLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/users/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let user of response.data) {
            let name = user.MATRICOLA + " " + user.COGNOME + " " + user.NOME;
            user.text = name;
          }
          this.allusers = response.data;
          this.usersLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
    },
  },
};
</script>